<template>
  <div class="eleventh">
    <div class="eleventh-inner container">
      <section class="eleventh-left wow" data-wow-offset="100">
        <h2 class="eleventh-left__title">
          Наши контакты
        </h2>
        <div class="eleventh-left__tabs">
          <Tabs :tabs="tabs" @select="changeCurrentTab" />
        </div>
        <div class="eleventh-left-bottom">
          <span class="eleventh-left-bottom__text">Телефоны:</span>
          <a
            href="tel:+77172904904"
            class="eleventh-left-bottom__text--strong"
            >{{ changeValue.phone1 }}</a
          >
          <a
            href="tel:+77088904904"
            class="eleventh-left-bottom__text--strong"
            >{{ changeValue.phone2 }}</a
          >
          <span class="eleventh-left-bottom__text pt-15">Адрес отдела:</span>
          <span
            class="eleventh-left-bottom__text--strong-ml"
            v-for="(adress, i) in changeValue.address"
            :key="i"
            >{{ adress }}</span
          >
        </div>
      </section>
      <div class="eleventh-right wow" data-wow-offset="100">
        <yandex-map
          class="eleventh-right__map"
          :coords="changeValue.mapCoord"
          :zoom="zoom"
          :controls="controls"
          @click="setNewYandexCoords"
        >
          <ymap-marker
            v-for="(coord, i) in changeValue.coords"
            :key="i"
            :marker-id="i"
            :coords="coord"
            :icon="markerIcon"
          />
        </yandex-map>
      </div>
    </div>
    <Footer @open="isShowTermModal = true" />
    <!-- <TermModal v-if="isShowTermModal" @close="isShowTermModal = false" /> -->
  </div>
</template>

<script>
import Tabs from '@/components/molecules/Tabs/index.vue'
import Footer from '@/components/atoms/Footer/index.vue'
// import TermModal from '@/components/atoms/Modals/TermModal.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { WOW } from 'wowjs'

export default {
  name: 'EleventhSection',
  components: { Tabs, yandexMap, ymapMarker, Footer },
  data() {
    return {
      currentTab: 0,
      // isShowTermModal: false,
      tabs: [
        {
          label: 'Шымкент',
        },
        {
          label: 'Астана',
        },
      ],
      zoom: 13,
      controls: ['zoomControl'],
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/images/icons/marker.svg'),
        imageSize: [54, 54],
        imageOffset: [0, 0],
        content: '',
        contentOffset: [0, 15],
        contentLayout:
          '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>',
      },
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  // watch: {
  //   isShowTermModal: {
  //     handler(val) {
  //       this.$store.commit('Modals/updateStatus', val)
  //     },
  //   },
  // },
  computed: {
    changeValue() {
      switch (this.currentTab) {
        case 1:
          return {
            phone1: '+7 7172 904-904',
            phone2: '+7 708 890-49-04',
            address: {
              1: 'ул. К.Мухамедханова 9 (ЖК Orken Deluxe)',
              2: 'ул. Сыганак 58, (ЖК Европа Палас)',
            },
            coords: {
              1: [51.138615, 71.400825],
              2: [51.123206, 71.425602],
            },
            mapCoord: [51.123206, 71.425602],
          }
        default: {
          return {
            phone1: '+7 7172 904-904',
            phone2: '+7 708 890-49-04',
            address: {
              1: 'ул. Кунаева 89 (ЖК Сырдария)',
            },
            coords: {
              1: [42.343924, 69.608701],
            },
            mapCoord: [42.343924, 69.608701],
          }
        }
      }
    },
  },
  methods: {
    changeCurrentTab(val) {
      this.currentTab = val
    },
    setNewYandexCoords(e) {
      this.yandexCoords = e.get('coords')
    },
  },
}
</script>

<style lang="scss" scoped>
.eleventh {
  width: 100%;
  height: fit-content;

  &-inner {
    gap: 50px;
    justify-content: space-between;
    @include flex-row();

    @include tablet-portrait {
      gap: 30px;
      flex-direction: column;
    }

    @include mobile-landscape {
    }
    @include mobile {
      gap: 16px;
    }
  }

  &-left {
    align-items: flex-start;
    max-width: 600px;
    animation: manifestation1 0.8s ease-in;
    @include flex-column();

    @include tablet-portrait {
      max-width: 80%;
    }
    @include mobile-landscape {
      max-width: none;
      width: 100%;
    }
    &__title {
      padding-bottom: 40px;
      @include font-styles(48px, 56px, 700, $c-dark, 0, $font-third);

      @include mobile {
        padding-bottom: 14px;
        @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
      }
    }

    &-bottom {
      max-width: 600px;
      padding-top: 30px;
      gap: 4px;
      @include flex-column();
      padding-bottom: 20px;

      @include tablet-portrait {
        padding-top: 30px;
      }

      @include mobile {
        padding-top: 16px;
      }

      &__text {
        @include font-styles(15px, 21px, 400, $c-gray);

        @include mobile {
          @include font-styles(12px, 16px, 400, $c-gray);
        }
      }

      &__text--strong {
        display: inline-block;
        @include font-styles(32px, 36px, 700, $c-dark);
        text-decoration: none;

        @include mobile {
          padding-top: 5px;
          @include font-styles(22px, 18px, 700, $c-dark);
        }

        &-ml {
          @include font-styles(18px, 24px, 700, $c-dark);

          @include mobile {
            @include font-styles(15px, 18px, 700, $c-dark);
          }
        }
      }
    }
  }
  &-right {
    animation: manifestation1 1s ease-in;
    max-width: 826px;
    width: 100%;
    height: 320px;

    @include laptop {
      max-width: 600px;
    }
    @include tablet-portrait {
      max-width: none;
      width: 100%;
    }

    &__map {
      border-radius: 120px 40px 40px 40px;
      overflow: hidden;
      width: 100%;
      height: 100%;

      @include tablet-portrait {
        border-radius: 60px 20px 20px 20px;
      }
    }
  }
}

::v-deep .tab {
  @include tablet-portrait {
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      min-width: fit-content;
    }
  }
}

.pt-15 {
  padding-top: 15px;
}
</style>
